"use strict";

if (isPreview) {

  // open raw xml mode in new tab on ctrl+alt+r
  (function open_raw_mode(){
    const url = (window.location + '').split('#')[0];
    $(window).keypress(function(e){
      const code = e.keyCode || e.which;
      if(code == 174) { // 174 = ctrl+alt+r or altgr+r
        const params = url.split('?')[1] ? '?' + url.split('?')[1] : '';
        const newUrl = url.split('?')[0] + '/raw' + params;
        window.open(newUrl,'_blank');
      }
    });
  })();
  
  $(function(){
    var perfData     = window.performance.timing; 
    var pageLoadTime = perfData.loadEventEnd - perfData.navigationStart;
    var renderTime   = perfData.domComplete - perfData.domLoading;
  
    if (pageLoadTime > 3000) console.error('page load takes too much time: '+pageLoadTime);
    if (renderTime > 1500) console.error('rendering takes too much time: '+renderTime);
  });
}
else {

  // google analytics
  //if (gaCode) {
  //  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','//www.google-analytics.com/analytics.js','ga');
  //  ga('create',gaCode,'auto');
  //  ga('send','pageview');
  //}
  
}

// website exit
(function external_in_new_window(){
  var domain = document.location.protocol + '//' + document.location.hostname;
  $.expr[':'].external = function (a) {
    return a.href.indexOf('http') == 0 && a.href.indexOf(domain) != 0;
  };
  $('a:external').each(function(){
    $(this).attr('target','_blank');
  });
})();

// build email links (spam prevention)
$('a.email').each(function(){
	var pre       = $(this).data('pre');
	var after     = $(this).data('after');
	var textPre   = $(this).data('text-pre');
	var textAfter = $(this).data('text-after');
	$(this).attr('href', 'mailto:' + pre + '@' + after);
	if (textPre) $(this).text(textPre + '@' + textAfter);
});

// left/right placement of tooltips 
if ($('.content')[0]) {
  var leftPosContent = $('.content').offset().left;
  var widthContent   = $('.content').width();
  $('.hint--bottom').each(function(){
    var leftPos = $(this).offset().left;
    if (widthContent / 2 < leftPos - leftPosContent) $(this).addClass('hint--leftside');
  });
}

// collapse and popup large tables
$('.table-zoom')
.each(function(){
  var minMargin   = 250;
  var zoombutton  = $(this);
  var table       = zoombutton.parent();
  var tableHeight = table.height();
  var positionTop = tableHeight > 600 ? minMargin : tableHeight / 2;
  
  zoombutton.css('top',positionTop+'px');
  
  if (tableHeight > 600) {
     var tableTop = table.offset().top;
    $(window).scroll(function(){
      var tableScrollPos = $(this).scrollTop() - tableTop;
       if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
         positionTop = minMargin + tableScrollPos;
         zoombutton.css('top',positionTop+'px');
       }
     });
   }
})
.click(function(){
  $(this).parent().addClass('popup');
})
;

$('.table-close').click(function(){
  $(this).parent().removeClass('popup');
});

// fix left menu on page scroll
$(window).scroll(function(){
  var navHeight  = $('.sidebar-left').height();
  var pageHeight = $('.content').height();
  var maxMT      = pageHeight - navHeight - 80;
  if ($(window).height() > navHeight + 20 && pageHeight > navHeight && pageHeight > 1000) {
    var scrollPos = $(this).scrollTop();
    var margintop = scrollPos > 240 ? scrollPos - 240 : 0;
    margintop     = margintop > maxMT ? maxMT : margintop;
    $('.sidebar-left').css('margin-top',margintop);
  }
});

$('.infographic').click(function(){
  $('.infographic').toggleClass('expand');
});